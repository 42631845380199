<template>
	<v-container>
		<v-btn @click="showAddUserDialog = true" :loading="loading" class="mb-4" color="primary">Add User</v-btn>
		<v-dialog v-model="showAddUserDialog" max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">Add User</span>
				</v-card-title>
				<v-card-text>
					<v-form @submit.prevent="addUser">
						<v-text-field v-model="newUser.firstName" label="First Name" required></v-text-field>
						<v-text-field v-model="newUser.email" label="Email" required></v-text-field>
						<v-select v-model="newUser.role" :items="roles" label="Role" required></v-select>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="showAddUserDialog = false">Cancel</v-btn>
							<v-btn color="blue darken-1" type="submit">Add</v-btn>
						</v-card-actions>
					</v-form>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-overlay :value="loading">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>

		<v-alert v-if="error" type="error" class="mt-4">
			<v-icon left>mdi-alert-circle</v-icon>{{ error }}
		</v-alert>

		<v-data-table :headers="headers" :items="users" class="elevation-1">
			<template v-slot:[`item.actions`]="{ item }">
				<v-row class="justify-end">
					<v-btn color="green" @click="sendWelcome(item.email, item.firstName)" class="mr-2">
						<v-icon>mdi-email</v-icon> Send Welcome
					</v-btn>
					<v-btn color="orange" @click="resetPassword(item.email, item.firstName)" class="mr-2">
						<v-icon>mdi-lock-reset</v-icon> Reset Password
					</v-btn>
					<v-btn color="red" @click="removeUser(item.email)">
						<v-icon>mdi-delete</v-icon> Remove
					</v-btn>
				</v-row>
			</template>
		</v-data-table>

		<!-- Toast Notifications -->
		<v-snackbar v-model="toast.show" :timeout="toast.timeout" :color="toast.color" top>
			{{ toast.message }}
			<v-btn color="white" text @click="toast.show = false">Close</v-btn>
		</v-snackbar>
	</v-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import backendService from "../services/backendService";

export default {
	name: "AdminView",
	setup() {
		const users = ref([]);
		const showAddUserDialog = ref(false);
		const newUser = ref({
			firstName: '',
			email: '',
			role: 'User'
		});
		const roles = ref(['Admin', 'Family', 'User', 'Guest']);
		const loading = ref(true);
		const error = ref('');

		const toast = ref({
			show: false,
			message: '',
			color: '',
			timeout: 3000,
		});

		const headers = [
			{ text: 'Name', value: 'firstName' },
			{ text: 'Email', value: 'email' },
			{ text: 'Role', value: 'role' },
			{ text: 'Status', value: 'status' },
			{ text: 'Actions', value: 'actions', sortable: false }
		];

		const fetchUsers = async () => {
			try {
				const data = await backendService.get('/admin/listusers');
				users.value = data;
				loading.value = false;
			} catch (err) {
				console.error('Error fetching users:', err);
				error.value = 'Failed to load user list';
				loading.value = false;
			}
		};

		const addUser = async () => {
			loading.value = true;
			try {
				const response = await backendService.post('/admin/createuser', newUser.value);
				users.value.push(response);
				showAddUserDialog.value = false;
				newUser.value = { firstName: '', email: '', role: 'User' };
				showToast('User added successfully', 'success');
			} catch (err) {
				console.error('Error adding user:', err);
				showToast('Failed to add user', 'error');
			} finally {
				loading.value = false;
			}
		};

		const removeUser = async (email) => {
			loading.value = true;
			try {
				await backendService.post('/admin/removeuser', { email });
				users.value = users.value.filter(user => user.email !== email);
				showToast('User removed successfully', 'success');
			} catch (err) {
				console.error('Error removing user:', err);
				showToast('Failed to remove user', 'error');
			} finally {
				loading.value = false;
			}
		};

		const sendWelcome = async (email, firstName) => {
			try {
				await backendService.post('/sendWelcome', { email, firstName });
				showToast('Welcome email sent', 'success');
			} catch (err) {
				console.error('Error sending welcome email:', err);
				showToast('Failed to send welcome email', 'error');
			}
		};

		const resetPassword = async (email, firstName) => {
			try {
				await backendService.post('/sendPasswordReset', { email, firstName });
				showToast('Password reset email sent', 'success');
			} catch (err) {
				console.error('Error sending password reset email:', err);
				showToast('Failed to send password reset email', 'error');
			}
		};

		const showToast = (message, color) => {
			toast.value.message = message;
			toast.value.color = color;
			toast.value.show = true;
		};

		onMounted(fetchUsers);

		return {
			users,
			showAddUserDialog,
			newUser,
			roles,
			loading,
			error,
			headers,
			fetchUsers,
			addUser,
			removeUser,
			sendWelcome,
			resetPassword,
			toast,
		};
	}
};
</script>

<style scoped></style>

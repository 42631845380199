import { createRouter, createWebHistory } from 'vue-router';
import MoviesView from './views/MoviesView.vue';
import TVShowView from './views/TVShowView.vue';
import GamesView from './views/GamesView.vue';
import BooksView from './views/BooksView.vue';
import APIExplorerView from './views/APIExplorerView.vue';
import SoftwareView from './views/SoftwareView.vue';
import AudiobooksView from './views/AudiobooksView.vue';
import DownloadView from './views/DownloadView.vue';
import NotFoundView from './views/NotFoundView.vue';
import LoginView from './views/LoginView.vue';
import ResetPasswordView from './views/ResetPasswordView.vue';
import AdminView from './views/AdminView.vue';
import HomeView from './views/HomeView.vue';

const routes = [
	{ path: '/', component: HomeView },
	{ path: '/movies', component: MoviesView },
	{ path: '/shows', component: TVShowView },
	{ path: '/games', component: GamesView },
	{ path: '/software', component: SoftwareView },
	{ path: '/books', component: BooksView },
	{ path: '/audiobooks', component: AudiobooksView },
	{ path: '/downloads', component: DownloadView },
	{ path: '/apiExplorer', component: APIExplorerView },
	{ path: '/admin', component: AdminView },

	{ path: '/login', component: LoginView },
	{ path: '/resetPassword', component: ResetPasswordView },
	{ path: '/:catchAll(.*)', component: NotFoundView }  // catch all route for 404 NotFound
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;

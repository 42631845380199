import { createApp } from 'vue';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import router from './Router';

const vuetify = createVuetify({
	components,
	directives,
	icons: {
		iconfont: 'mdi', // default - only for display purposes
	},
	theme: {
		defaultTheme: 'customDark',
		themes: {
			customDark: {
				dark: true,
				colors: {
					background: '#101010', // Darker gray background
					surface: '#121212', // Dark surface, slightly lighter than background
					primary: '#ff0000', // Red primary color for buttons and accents
					secondary: '#ff4d4d', // Slightly lighter red for secondary elements
					accent: '#ff4d4d', // Accent color also set to red
					error: '#ff0000', // Red for error messages
					info: '#ffffff', // White for informational text
					success: '#00ff00', // Green for success messages
					warning: '#ffff00', // Yellow for warnings
				},
			},
		},
	},
});

const app = createApp(App);
app.use(VueCookies);
app.use(vuetify);
app.use(router);
app.mount('#app');

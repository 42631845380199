<template>
	<div>
		<ResultComponent topUrl="/topAudiobooks" searchUrl="/searchAudiobooks" detailsUrl="/detailsAudiobooks" />
	</div>
</template>

<script>
import ResultComponent from "@/components/ResultComponent.vue";

export default {
	components: {
		ResultComponent,
	},
};
</script>

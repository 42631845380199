<template>
					<h1>Welcome to API Explorer</h1>
				<WebDownloaderComponent />
				<hr />
				<h2 class="mt-8">Scrapers</h2>
				<ScraperTableComponent />
</template>

<script>
import WebDownloaderComponent from "../components/APIExplorer/WebDownloaderComponent";
import ScraperTableComponent from "../components/APIExplorer/ScraperTableComponent";

export default {
	name: "APIExplorer",
	components: {
		WebDownloaderComponent,
		ScraperTableComponent,
	},
};
</script>

<template>
	<div class="loading-container" :class="{ 'text-centered': centeredText }">
		<v-progress-circular indeterminate color="primary" class="loading-spinner"></v-progress-circular>
		<span class="loading-text">{{ loadingText }}</span>
	</div>
</template>

<script>
export default {
	name: "PirateLoadingSpinner",
	props: {
		centeredText: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loadingQuotes: [
				"Hoisting the sails...",
				"Swabbing the deck...",
				"Charting the course...",
				"Loading the cannons...",
				"Plundering the treasures...",
				"Raising the Jolly Roger...",
				"Setting a course for adventure...",
				"Counting the doubloons...",
				"Shiver me timbers...",
				"Checking the map for buried treasure...",
				"Bracing for rough seas...",
				"Unfurling the sails...",
				"Loading the booty...",
				"Scouring the horizon...",
				"Keeping a weather eye open...",
				"Splicing the mainbrace...",
				"Plotting a mutiny...",
				"Scraping the barnacles...",
				"Securing the hatches...",
				"Filling the rum barrels...",
				"Battening down the hatches...",
				"Preparing to board...",
				"Navigating by the stars...",
				"Sharpening the cutlasses...",
				"Furling the jibs...",
				"Checking the compass...",
				"Rummaging through the cargo hold...",
				"Spying land on the horizon...",
				"Filling the powder kegs...",
				"Counting the loot...",
				"Plotting a new course...",
				"Sounding the depth...",
				"Reefing the sails...",
				"Trimming the sheets...",
				"Checking the rigging...",
				"Preparing to cast off...",
				"Anchoring in the bay...",
				"Scouting for enemy ships...",
				"Listening to the captain’s orders...",
				"Scouring the seas for riches...",
				"Setting sail at dawn...",
				"Loading the grappling hooks...",
				"Preparing to weigh anchor...",
				"Double-checking the treasure map...",
				"Sharpening the anchors...",
				"Cleaning the muskets...",
				"Plotting a raid on a merchant ship...",
				"Guarding the treasure chest...",
				"Tying the knots tight...",
				"Finding the X that marks the spot...",
				"Braving the stormy seas...",
			],
			loadingText: '',
		};
	},
	mounted() {
		this.loadingText = this.getRandomQuote();
	},
	methods: {
		getRandomQuote() {
			const randomIndex = Math.floor(Math.random() * this.loadingQuotes.length);
			return this.loadingQuotes[randomIndex];
		},
	},
};
</script>

<style scoped>
.loading-container {
	display: flex;
	align-items: center;
}

.text-centered {
	flex-direction: column;
	text-align: center;
}

.loading-text {
	margin-left: 1rem;
}

.text-centered .loading-text {
	margin-left: 0;
	margin-top: 0.5rem;
}
</style>

<template>
	<v-container style="max-width: 1500px;">
		<v-row>
			<v-col>
				<v-card min-height="400px" class="pa-4">
					<v-card-title class="red--text">Torrent Web Client</v-card-title>

					<!-- Input Section for Adding Magnet Links -->
					<v-row class="pa-4" align="center">
						<v-col cols="12" sm="9" md="10">
							<v-text-field v-model="newMagnet" label="Add Magnet Link"
								placeholder="Paste your magnet link here"
								:error-messages="magnetError ? [magnetErrorMessage] : []" clearable></v-text-field>
						</v-col>
						<!-- Conditionally render the Add button -->
						<v-col v-if="hasMagnetInput" cols="12" sm="3" md="2" class="d-flex justify-center">
							<v-btn color="primary" @click="addMagnet">Add</v-btn>
						</v-col>
					</v-row>

					<!-- Show spinner during the first loading -->
					<v-row v-if="initialLoading" class="d-flex justify-center align-center" style="min-height: 200px;">
						<PirateLoadingSpinner />
					</v-row>

					<!-- Magnet Links Section -->
					<v-list v-else style="overflow: hidden;">
						<template v-if="magnets.length > 0">
							<AddTorrentItem v-for="magnet in magnets" :key="magnet" :magnet="magnet"
								:fetchMagnets="fetchMagnets" />
						</template>
						<template v-else>
							<v-list-item>
								<v-list-item-title>
									No magnet links available. Use the Copy button in any of the
									download index pages to start your download.
								</v-list-item-title>
							</v-list-item>
						</template>
					</v-list>

					<!-- Torrents Section -->
					<v-list class="mt-5" style="overflow: hidden;">
						<TorrentItem v-for="(torrent, index) in sortedTorrents" :key="'torrent-' + index"
							:torrent="torrent" />
					</v-list>

					<!-- Error Div -->
					<div v-if="showErrorOverlay" class="error-overlay">
						<div class="overlay-content">
							<v-icon class="broken-wifi-icon" color="red" size="150">
								mdi-wifi-strength-off
							</v-icon>
						</div>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import backendService from "@/services/backendService";
import magnetService from "@/services/magnetService";
import AddTorrentItem from "@/components/downloader/AddTorrentItem.vue";
import TorrentItem from "@/components/downloader/TorrentItem.vue";
import PirateLoadingSpinner from "@/components/generic/PirateLoadingSpinner.vue";

export default {
	name: "TorrentWebClient",
	components: {
		AddTorrentItem,
		TorrentItem,
		PirateLoadingSpinner,
	},
	data() {
		return {
			torrents: [],
			magnets: [],
			fetching: false, // Tracks if a fetch request is being made
			initialLoading: true, // For showing the spinner only the first time
			showErrorOverlay: false, // To handle the error overlay
			intervalId: null, // For storing the interval ID
			newMagnet: "", // Holds the value of the new magnet link input
			magnetError: false, // Tracks if there's an error with the magnet input
			magnetErrorMessage: "", // Holds the error message for invalid magnet
		};
	},
	computed: {
		sortedTorrents() {
			return [...this.torrents].sort(
				(a, b) => new Date(b.addedDate) - new Date(a.addedDate)
			);
		},
		hasMagnetInput() {
			return this.newMagnet.trim().length > 0;
		},
	},
	created() {
		this.fetchMagnets();
		this.startFetchingTorrentsInterval(); // Start the interval for fetching torrents
		window.scrollTo(0, 0); // Scroll to top when the component is created
	},
	methods: {
		async fetchTorrents() {
			// Skip fetching if we're already sending a request
			if (this.fetching) {
				return;
			}

			this.fetching = true;
			try {
				const response = await backendService.get("/downloader/getTorrents");
				if (response.result === "success") {
					this.torrents = response.arguments.torrents;
					this.showErrorOverlay = false; // Hide the overlay on success
					this.initialLoading = false; // Set to false after the first fetch
				} else {
					console.error("Error: Failed to fetch torrents");
					this.showErrorOverlay = true; // Show the overlay on failure
					this.initialLoading = false; // Stop loading state even on error
				}
			} catch (error) {
				console.error("Error fetching torrents:", error);
				this.showErrorOverlay = true; // Show the overlay on error
				this.initialLoading = false; // Stop loading state even on error
			} finally {
				this.fetching = false; // Reset fetching flag after the request completes
			}
		},
		startFetchingTorrentsInterval() {
			// Set an interval to fetch torrents every 0.5 seconds
			this.intervalId = setInterval(() => {
				this.fetchTorrents();
			}, 500);
			this.fetchTorrents();
		},
		stopFetchingTorrentsInterval() {
			if (this.intervalId) {
				clearInterval(this.intervalId); // Clear the interval
				this.intervalId = null;
			}
		},
		fetchMagnets() {
			this.magnets = magnetService.getMagnets();
		},
		async addMagnet() {
			// Trim whitespace from the input
			const magnetLink = this.newMagnet.trim();

			// Validate that the magnet link starts with 'magnet:'
			if (!magnetLink.startsWith("magnet:")) {
				this.magnetError = true;
				this.magnetErrorMessage =
					"Invalid magnet link. It should start with 'magnet:'.";
				return;
			}

			// Optional: Further validation can be added here (e.g., proper magnet URI structure)

			// If valid, add the magnet to the service and update the list
			try {
				await backendService.get(
					`/downloader/addTorrent?magnet=${encodeURIComponent(magnetLink)}`
				);
				this.newMagnet = ""; // Clear the input field
				this.magnetError = false; // Reset the error state
				this.fetchMagnets(); // Refresh the magnets list
			} catch (error) {
				console.error("Error adding magnet:", error);
				this.magnetError = true;
				this.magnetErrorMessage =
					"Failed to add magnet link. Please try again.";
			}
		},
	},
	beforeUnmount() {
		this.stopFetchingTorrentsInterval(); // Stop the interval when the component is destroyed
	},
};
</script>

<style scoped>
.red {
	background-color: red !important;
}

.error-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	/* Black half transparent background */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
	/* Ensure it's on top */
}

.overlay-content {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.broken-wifi-icon {
	width: 150px;
	height: 150px;
}

.pa-4 {
	padding: 0px !important;
}

/* Ensure the card is positioned relative to contain the absolute overlay */
.v-card {
	position: relative;
}
</style>

<template>
	<BaseResultComponent :initialFetchUrl="initialFetchUrl" :searchUrl="searchUrl" :detailsUrl="detailsUrl"
		:magnetUrl="magnetUrl" :showSearch="showSearch" :limit="limit" :showTopNew="showTopNew">
		<template #table-headers>
			<th class="table-header default-padding">Title</th>
			<th class="table-header default-padding">Author</th>
			<th class="table-header default-padding">Series</th>
			<th class="table-header default-padding">Language</th>
			<th class="table-header default-padding">Format</th>
			<th class="table-header default-padding">Size</th>
			<th class="table-header default-padding">Mirrors</th>
		</template>

		<template #table-row="{ item, index }">
			<BookResultComponentRow :item="item" :detailsUrl="detailsUrl" :isOdd="index % 2 !== 0" />
		</template>
	</BaseResultComponent>
</template>

<script>
import BaseResultComponent from "./BaseResultComponent.vue";
import BookResultComponentRow from "./BookResultComponentRow.vue";

export default {
	name: "BookResultComponent",
	components: {
		BaseResultComponent,
		BookResultComponentRow,
	},
	props: {
		initialFetchUrl: {
			type: String,
			required: false,
			default: '',
		},
		searchUrl: {
			type: String,
			required: true,
		},
		detailsUrl: {
			type: String,
			required: true,
		},
		magnetUrl: {
			type: String,
			required: false,
			default: '',
		},
		showSearch: {
			type: Boolean,
			default: true,
		},
		limit: {
			type: Number,
			default: null, // No limit on book pages
		},
		showTopNew: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

class MagnetService {
	constructor() {
		this.magnetItems = this.filterMagnetItems(this.loadMagnetsFromLocalStorage());
	}

	loadMagnetsFromLocalStorage() {
		const magnets = localStorage.getItem('magnets');
		if (magnets) {
			return JSON.parse(magnets);
		}
		return [];
	}

	getMagnets() {
		// Only return magnet links from the last hour
		return this.filterMagnetItems(this.magnetItems).map(m => m.magnetLink ?? "?");
	}

	addMagnet(magnetLink) {
		const magnetItem = { date: new Date(), magnetLink: magnetLink };
		// Check if magnet already exists
		const existingMagnet = this.magnetItems.find(m => m.magnetLink === magnetLink);
		if (existingMagnet) {
			// Update the date
			existingMagnet.date = magnetItem.date;

			// Put it at the top of the list
			this.magnetItems = this.magnetItems.filter(m => m.magnetLink !== magnetLink);
			this.magnetItems.push(existingMagnet);
			this.saveMagnetsToLocalStorage();
			return;
		}

		this.magnetItems.push(magnetItem);
		this.saveMagnetsToLocalStorage();
	}

	deleteMagnet(magnetLink) {
		this.magnetItems = this.magnetItems.filter(m => m.magnetLink !== magnetLink);
		this.saveMagnetsToLocalStorage();
	}

	saveMagnetsToLocalStorage() {
		this.magnetItems = this.filterMagnetItems(this.magnetItems);
		localStorage.setItem('magnets', JSON.stringify(this.magnetItems));
	}

	filterMagnetItems(magnetItems) {
		//const now = new Date();
		//const lastHour = new Date(now.getTime() - 1000 * 60 * 60);
		//const filteredMagnetItems = magnetItems.filter(m => new Date(m.date) > lastHour);
		//return filteredMagnetItems;
		return magnetItems;
	}

	getNameFromMagnetLink(magnetLink) {
		if (!magnetLink)
			return "?";
		const name = magnetLink.match(/dn=([^&]+)/);
		if (name) {
			// Replace + with dot
			return decodeURIComponent(name[1]).replace(/\+/g, ".");
		}
		return "?";
	}
}

export default new MagnetService();

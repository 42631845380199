<template>
	<v-app>
		<v-app-bar v-if="showTopbar" dark color="black">
			<v-app-bar-nav-icon @click="drawer = !drawer" class="navigation-drawer"></v-app-bar-nav-icon>
			<v-img :src="logo" alt="Logo" class="logo" max-width="40" @click="navigateHome"></v-img>
			<v-toolbar-title>{{ currentPageTitle }}</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-tabs v-model="currentTab" class="navigation-tabs">
				<template v-if="isLoading">
					<PirateLoadingSpinner class="mt-4 mr-4" />
				</template>
				<template v-else-if="fetchError">
					<span class="mt-4 mr-4 error-text">{{ fetchError }}</span> <!-- Show error message -->
				</template>
				<template v-else>
					<v-tab v-for="page in navigationPages" :key="page.name" :to="page.route">
						{{ page.name }}
					</v-tab>
				</template>
			</v-tabs>
		</v-app-bar>

		<v-navigation-drawer v-if="showTopbar" v-model="drawer" temporary app>
			<v-list>
				<template v-if="isLoading">
					<PirateLoadingSpinner class="ml-2 mt-4" />
				</template>
				<template v-else-if="fetchError">
					<span class="ml-2 error-text">{{ fetchError }}</span> <!-- Show error message -->
				</template>
				<template v-else>
					<v-list-item v-for="page in navigationPages" :key="page.name" :to="page.route"
						@click="drawer = false" class="list-item">
						<v-icon>{{ page.icon }}</v-icon>
						<span class="ml-2 list-item-title">{{ page.name }}</span>
					</v-list-item>
				</template>
			</v-list>
		</v-navigation-drawer>
		<!-- Main Content with Router View and Keep-Alive -->
		<v-main>
			<router-view v-slot="{ Component }">
				<keep-alive exclude="TorrentWebClient">
					<component :is="Component" />
				</keep-alive>
			</router-view>
		</v-main>
	</v-app>
</template>

<script>
import logo from "@/assets/Logo.png";
import userProfileService from "@/services/userProfileService";
import PirateLoadingSpinner from "@/components/generic/PirateLoadingSpinner.vue";

export default {
	name: "TorrentTreasure",
	data() {
		return {
			currentTab: null,
			drawer: false,
			logo,
			isLoading: true,
			userRole: 'guest',
			fetchError: null,  // New property to track errors
			pages: [
				{ name: "Home", route: "/", icon: "mdi-home" },
				{ name: "TV Shows", route: "/shows", icon: "mdi-television-classic" },
				{ name: "Movies", route: "/movies", icon: "mdi-filmstrip" },
				{ name: "Games", route: "/games", icon: "mdi-gamepad-variant" },
				{ name: "Software", route: "/software", icon: "mdi-application-cog" },
				{ name: "Books", route: "/books", icon: "mdi-book-open-page-variant", role: ["Admin"] },
				{ name: "Audiobooks", route: "/audiobooks", devOnly: true, icon: "mdi-book-music-outline" },
				{ name: "Downloader", route: "/downloads", icon: "mdi-download-circle", role: ["Admin", "Family"] },
				{ name: "API Explorer", route: "/apiExplorer", icon: "mdi-api", role: ["Admin"] },
				{ name: "Users", route: "/admin", icon: "mdi-cog-outline", role: ["Admin"] },
				{ name: "Login", route: "/login", hideFromNav: true, icon: "mdi-login-variant" },
				{ name: "Reset Password", route: "/resetPassword", hideFromNav: true },
			],
		};
	},
	computed: {
		currentPageTitle() {
			const page = this.pages.find(page => page.route === this.$route.path);
			return page ? page.name : 'Page Not Found';
		},
		showTopbar() {
			return this.$route.path !== '/login' && this.$route.path !== '/resetPassword';
		},
		filteredPages() {
			const isDevMode = process.env.NODE_ENV === 'development';
			return this.pages.filter(page => {
				const hasRole = !page.role || page.role.includes(this.userRole);
				return (!page.devOnly || isDevMode) && hasRole;
			});
		},
		navigationPages() {
			return this.filteredPages.filter(page => !page.hideFromNav);
		},
	},
	watch: {
		$route() {
			this.updateTitle();
		}
	},
	created() {
		this.fetchUserProfile();
		this.updateTitle();
	},
	methods: {
		navigateHome() {
			this.$router.push('/');
		},
		updateTitle() {
			const page = this.pages.find(page => page.route === this.$route.path);
			this.currentTab = page?.route || null;
			document.title = page?.name || 'Page Not Found';
		},
		async fetchUserProfile() {
			try {
				const profile = await userProfileService.asyncGetUserProfile();
				if (!profile) {
					throw new Error("Failed to fetch user profile.");
				}

				this.userRole = profile?.role || 'guest';  // Store the role in userRole
			} catch (error) {
				this.fetchError = "Failed to fetch user profile. Please try again later.";
			} finally {
				this.isLoading = false;
			}
		}
	},
	components: {
		PirateLoadingSpinner
	}
};
</script>


<style>
@import 'vuetify/styles';

body {
	background-color: #121212;
	color: white;
}

.logo {
	margin-left: 16px;
	cursor: pointer;
}

.v-toolbar-title {
	margin-inline-start: 8px !important;
}

.navigation-tabs {
	display: block !important;
}

.navigation-drawer {
	display: none !important;
}

.error-text {
	color: red;
	font-weight: bold;
}

.list-item {
	display: flex;
	align-items: center;
}

.list-item v-icon {
	margin-right: 16px;
}

.list-item-title {
	margin: 0;
}

@media (max-width: 1300px) {
	.navigation-tabs {
		display: none !important;
	}

	.navigation-drawer {
		display: block !important;
	}

	.logo {
		margin-left: 0px !important;
	}
}

@media (max-width: 600px) {
	.v-container {
		padding: 0px !important;
	}
}
</style>
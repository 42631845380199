<template>
	<v-dialog :value="value" max-width="500px" @input="updateValue">
		<v-card>
			<v-card-title>
				<span class="headline">Forgot Password</span>
				<v-btn icon @click="closeDialog" v-if="!loading && !forgotPasswordSent" class="ml-auto">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-form ref="forgotPasswordForm" v-model="forgotPasswordValid" v-if="!loading && !forgotPasswordSent">
					<v-text-field v-model="forgotFirstName" label="First Name" :rules="firstNameRules"
						required></v-text-field>
					<v-text-field v-model="forgotEmail" label="Email" :rules="emailRules" required></v-text-field>
					<v-btn color="primary" :disabled="!forgotPasswordValid"
						@click="sendForgotPasswordEmail">Send</v-btn>
				</v-form>
				<PirateLoadingSpinner v-if="loading" />
				<v-alert v-if="forgotPasswordSent && !loading" type="success" outlined class="mt-3">
					Email is sent to {{ forgotEmail }}
				</v-alert>
			</v-card-text>
			<v-card-actions v-if="!loading">
				<v-spacer></v-spacer>
				<v-btn color="primary" text @click="closeDialog" v-if="forgotPasswordSent">OK</v-btn>
				<v-btn color="secondary" text @click="closeDialog" v-if="!forgotPasswordSent">Cancel</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import backendService from '@/services/backendService';
import PirateLoadingSpinner from '@/components/generic/PirateLoadingSpinner.vue';

export default {
	name: 'ForgottenPasswordPopup',
	props: {
		value: {
			type: Boolean,
			required: true
		},
		email: {
			type: String,
			required: true
		}
	},
	components: {
		PirateLoadingSpinner
	},
	data() {
		return {
			forgotFirstName: '',
			forgotEmail: this.email,
			forgotPasswordSent: false,
			forgotPasswordValid: false,
			loading: false,
			firstNameRules: [
				v => !!v || 'First Name is required',
			],
			emailRules: [
				v => !!v || 'E-mail is required',
				v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
			],
		};
	},
	watch: {
		email(newVal) {
			this.forgotEmail = newVal;
		}
	},
	methods: {
		updateValue(newValue) {
			this.$emit('input', newValue);
		},
		async sendForgotPasswordEmail() {
			this.$refs.forgotPasswordForm.validate();
			if (this.forgotPasswordValid) {
				this.loading = true;
				try {
					await backendService.post('/sendPasswordReset', {
						firstName: this.forgotFirstName,
						email: this.forgotEmail,
					});
					this.forgotPasswordSent = true;
				} catch (error) {
					this.$emit('error', error.message || 'Failed to send email. Please try again.');
				} finally {
					this.loading = false;
				}
			}
		},
		closeDialog() {
			this.$emit('close');
		}
	}
};
</script>

<style scoped></style>

<template>
	<v-dialog v-model="dialog" max-width="500px">
		<v-card>
			<v-card-title>
				<span class="headline">Edit Scraper</span>
			</v-card-title>
			<v-card-text>
				<v-text-field v-model="url" label="Enter URL" required></v-text-field>
				<v-text-field v-model="name" label="Enter Name" required></v-text-field>
				<v-text-field v-model="scraper" label="Enter Scraper"></v-text-field>
				<v-text-field v-model="functionName" label="Enter Function"></v-text-field>
				<v-select v-model="method" :items="methods" label="Select Method" required></v-select>
				<v-alert v-if="error" type="error" icon="mdi-alert-circle" dismissible>{{ error }}</v-alert>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
				<v-btn color="blue darken-1" text @click="updateScraperFunction">Update</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import backendService from "../../services/backendService";

export default {
	name: "EditScraperDialog",
	data() {
		return {
			dialog: false,
			url: "",
			name: "",
			scraper: "",
			functionName: "",
			method: "",
			error: "",
			methods: ["GET", "POST", "PUT", "DELETE"],
		};
	},
	methods: {
		openDialog(scraper) {
			this.url = scraper.url;
			this.name = scraper.name;
			this.scraper = scraper.scraper;
			this.functionName = scraper.function;
			this.method = scraper.method;
			this.dialog = true;
		},
		closeDialog() {
			this.dialog = false;
		},
		async updateScraperFunction() {
			if (!this.url || !this.name || !this.method) {
				this.error = "All fields are required.";
				return;
			}

			this.error = "";
			try {
				const response = await backendService.post(
					`/scraper/updateScraper/?name=${this.name}`,
					{
						url: this.url,
						name: this.name,
						scraper: this.scraper,
						function: this.functionName,
						method: this.method,
					}
				);
				if (response) {
					this.$emit("refreshScrapers");
					this.closeDialog();
				} else {
					this.error = "Failed to update scraper. Please try again.";
				}
			} catch (error) {
				this.error = "An error occurred while updating the scraper.";
				console.error(error);
			}
		},
	},
};
</script>

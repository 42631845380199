<template>
	<v-list-item>
		<v-divider></v-divider>
		<v-list-item-title class="green--text">{{ magnetName }}</v-list-item-title>
		<v-row>
			<v-col class="d-flex align-center"></v-col>
			<v-col class="d-flex justify-end">
				<v-btn icon small @click="startMagnet">
					<v-icon small class="green--text">mdi-check</v-icon>
				</v-btn>
				<v-btn icon small @click="deleteMagnet">
					<v-icon small class="red--text">mdi-close</v-icon>
				</v-btn>
			</v-col>
		</v-row>
	</v-list-item>
</template>

<script>
import magnetService from "@/services/magnetService";
import backendService from "@/services/backendService";

export default {
	props: {
		magnet: {
			type: String,
			required: true,
		},
		fetchMagnets: {
			type: Function,
			required: true,
		},
	},
	data() {
		return {
			magnetName: "",
		};
	},
	created() {
		this.magnetName = magnetService.getNameFromMagnetLink(this.magnet);
	},
	methods: {
		startMagnet() {
			backendService.get(`/downloader/addTorrent?magnet=${encodeURIComponent(this.magnet)}`);
			this.deleteMagnet();
		},
		deleteMagnet() {
			magnetService.deleteMagnet(this.magnet);
			this.fetchMagnets();
		},
	},
};
</script>


<style scoped>
.green--text {
	color: green !important;
}
</style>
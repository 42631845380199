<template>
  <v-container>
    <h1>Page Not Found</h1>
    <p>The page you are looking for does not exist.</p>
    <v-btn @click="navigateHome">Go to Home</v-btn>
  </v-container>
</template>

<script>
export default {
  name: 'NotFound',
  methods: {
    navigateHome() {
      this.$router.push('/');
    }
  }
};
</script>

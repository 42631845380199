<template>
	<tr :class="{ 'odd-row': isOdd, 'even-row': !isOdd }">
		<td class="default-padding" style="width: 25%; word-break: break-word">
			<strong>{{ item.title }}</strong>
		</td>
		<td class="default-padding" style="width: 20%; word-break: break-word">
			{{ item.author }}
		</td>
		<td class="default-padding" style="width: 15%; word-break: break-word">
			{{ item.series || 'N/A' }}
		</td>
		<td class="default-padding" style="width: 10%">
			{{ item.language }}
		</td>
		<td class="default-padding" style="width: 10%">
			{{ item.fileFormat }}
		</td>
		<td class="default-padding" style="width: 10%">
			{{ item.fileSize }}
		</td>
		<td class="default-padding" style="width: 20%">
			<a v-for="(mirror, index) in item.mirrors" :key="index" :href="mirror" target="_blank" class="mirror-link">
				Mirror {{ index + 1 }}
			</a>
		</td>
	</tr>
</template>

<script>
export default {
	name: "BookResultComponentRow",
	props: {
		item: {
			type: Object,
			required: true,
		},
		detailsUrl: {
			type: String,
			required: true,
		},
		isOdd: {
			type: Boolean,
			required: true,
		},
	},
	methods: {
		// Add any necessary methods for books here
	},
};
</script>

<style scoped>
.odd-row {
	background-color: #2f2f2f;
}

.even-row {
	background-color: #1f1f1f;
}

.mirror-link {
	display: block;
	color: #0055AA;
	text-decoration: underline;
}

.mirror-link:hover {
	color: #003366;
}
</style>

<template>
	<v-list-item>
		<v-divider></v-divider>
		<v-list-item-title>{{ torrent.name }}</v-list-item-title>
		<v-progress-linear :model-value="(torrent.percentDone * 100).toFixed(2)" :height="20" :color="progressBarColor"
			:striped="!isPausedOrStopped" :indeterminate="isStartingOrNoProgress && !isPausedOrStopped"
			bg-color="white">
			{{ (torrent.percentDone * 100).toFixed(2) }}%
			<span v-if="isPausedOrStopped" class="ml-1"> (paused)</span>
		</v-progress-linear>

		<v-row>
			<v-col class="d-flex align-center">
				<v-icon small class="mr-2">mdi-download</v-icon> <span class="non-wrapped"> {{
					formatRate(torrent.rateDownload) }}</span>
				<v-icon small class="ml-3 mr-2">mdi-upload</v-icon> <span class="non-wrapped">{{
					formatRate(torrent.rateUpload) }}</span>
				<v-icon small class="ml-3 mr-2">mdi-swap-vertical</v-icon> <span class="non-wrapped">{{
					torrent.uploadRatio.toFixed(3) }}</span>
			</v-col>
			<v-col class="d-flex justify-center align-center">
				<span class="mr-2 non-wrapped">Size: {{ formatSize(torrent.totalSize) }}</span>
			</v-col>

			<v-col class="d-flex justify-end">
				<v-btn icon small v-if="isPausedOrStopped" @click="startTorrent">
					<v-icon small>mdi-play</v-icon>
				</v-btn>
				<v-btn icon small v-if="isStartedOrDownloading" @click="pauseTorrent">
					<v-icon small>mdi-pause</v-icon>
				</v-btn>

				<!-- Settings Button -->
				<v-btn icon small @click="settingsDialogOpen = true">
					<v-icon small>mdi-cog</v-icon>
				</v-btn>

				<!-- Remove Button -->
				<v-btn icon small class="remove-icon" @click="deleteDialogOpen = true">
					<v-icon small>mdi-delete</v-icon>
				</v-btn>
			</v-col>
		</v-row>

		<!-- Settings Dialog Component -->
		<TorrentSettingsDialog v-if="settingsDialogOpen" :torrentId="torrent.id" @close="settingsDialogOpen = false" />

		<!-- Delete Dialog Component -->
		<RemoveTorrentPopup v-if="deleteDialogOpen" :torrentId="torrent.id" :torrentName="torrent.name"
			@close="deleteDialogOpen = false" @confirmDelete="deleteTorrent" />
	</v-list-item>
</template>

<script>
import TorrentSettingsDialog from './SettingsTorrentPopup.vue';
import RemoveTorrentPopup from './RemoveTorrentPopup.vue';
import backendService from "@/services/backendService";

export default {
	props: {
		torrent: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			settingsDialogOpen: false,
			deleteDialogOpen: false,
		};
	},
	computed: {
		isPausedOrStopped() {
			return this.torrent.status === 0;
		},
		isStartedOrDownloading() {
			return this.torrent.status !== 0;
		},
		isStartingOrNoProgress() {
			// Set the indeterminate state when starting or no bytes have been downloaded
			return this.torrent.status === 1 || this.torrent.percentDone === 0;
		},
		progressBarColor() {
			// Change the color to blue when the torrent is done or seeding
			if (this.torrent.status === 6 || this.torrent.status === 8) {
				return 'blue';
			}
			// Red color when downloading or paused
			return this.isPausedOrStopped ? 'gray' : 'red';
		},
	},
	methods: {
		formatRate(rate) {
			if (rate < 1024) {
				return rate + " B/sec";
			} else if (rate < 1024 * 1024) {
				return (rate / 1024).toFixed(1) + " KB/sec";
			} else if (rate < 1024 * 1024 * 1024) {
				return (rate / (1024 * 1024)).toFixed(1) + " MB/sec";
			} else {
				return (rate / (1024 * 1024 * 1024)).toFixed(1) + " GB/sec";
			}
		},
		formatSize(size) {
			if (size <= 0) {
				return "?";
			} else if (size < 1024) {
				return size + " B";
			} else if (size < 1024 * 1024) {
				return (size / 1024).toFixed(1) + " KB";
			} else if (size < 1024 * 1024 * 1024) {
				return (size / (1024 * 1024)).toFixed(1) + " MB";
			} else if (size < 1024 * 1024 * 1024 * 1024) {
				return (size / (1024 * 1024 * 1024)).toFixed(1) + " GB";
			} else {
				return (size / (1024 * 1024 * 1024 * 1024)).toFixed(1) + " TB";
			}
		},
		async startTorrent() {
			try {
				await backendService.post(`/downloader/startTorrent`, { torrentId: this.torrent.id });
				console.log(`Torrent ${this.torrent.name} started`);
			} catch (error) {
				console.error('Error starting torrent:', error);
			}
		},
		async pauseTorrent() {
			try {
				await backendService.post(`/downloader/stopTorrent`, { torrentId: this.torrent.id });
				console.log(`Torrent ${this.torrent.name} paused`);
			} catch (error) {
				console.error('Error pausing torrent:', error);
			}
		},
		async deleteTorrent(deleteData) {
			try {
				await backendService.post(`/downloader/deleteTorrent`, {
					torrentId: this.torrent.id,
					deleteData: deleteData,
				});
				console.log(`Torrent ${this.torrent.name} deleted`);
			} catch (error) {
				console.error('Error deleting torrent:', error);
			}
		},
	},
	components: {
		TorrentSettingsDialog,
		RemoveTorrentPopup,
	},
};
</script>

<style scoped>
.red {
	background-color: red !important;
}

.blue {
	background-color: blue !important;
}

.remove-icon {
	color: red;
}

.non-wrapped {
	white-space: nowrap;
}
</style>

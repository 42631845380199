<template>
	<v-container class="d-flex align-center justify-center main-container">
		<div class="reset-password-container">
			<v-card class="pa-5" max-width="1000">
				<div v-if="!initialLoading && !error && !resetSuccess">
					<v-card-title class="justify-center">
						<h1>{{ welcome ? 'Ahoy ' + firstName + ', to Pirate Plunder' : 'Reset yer password' }}</h1>
					</v-card-title>
					<v-card-text>
						<div v-if="welcome">
							<h3 class="mb-4">Arrr, here we be sailin' the high seas!</h3>
						</div>
						<br />
						<v-form ref="form" v-model="valid">
							<v-text-field v-model="email" label="Email" :rules="emailRules" required :readonly="true"
								autocomplete="email"></v-text-field>
							<v-text-field v-model="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
								:rules="passwordRules" :type="showPassword ? 'text' : 'password'" label="Password"
								@click:append="showPassword = !showPassword"
								@input="checkPasswordStrength; validateConfirmPassword" required
								autocomplete="new-password"></v-text-field>
							<v-text-field v-model="confirmPassword"
								:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="confirmPasswordRules"
								:type="showPassword ? 'text' : 'password'" label="Confirm Password"
								@click:append="showPassword = !showPassword" @input="validateConfirmPassword" required
								autocomplete="new-password"></v-text-field>
							<br />
							<v-btn color="primary" :loading="loading" @click="resetPassword"
								:disabled="!formValid || loading">
								{{ welcome ? 'Set Yer Password' : 'Reset Yer Password' }}
							</v-btn>
							<v-alert v-if="error" type="error" outlined class="mt-3">
								{{ errorMessage }}
							</v-alert>
						</v-form>
					</v-card-text>
				</div>

				<div class="retry-container" v-if="!initialLoading && error">
					<v-alert type="error" outlined class="mt-3">
						{{ errorMessage }}
					</v-alert>
					<br />
					<v-btn color="primary" @click="fetchResetPasswordInfo">
						Retry
					</v-btn>
				</div>

				<v-dialog v-model="initialLoading" hide-overlay persistent width="400">
					<v-card>
						<div class="text-center">
							<v-card-title class="headline">Getting password reset information</v-card-title>
						</div>
						<v-card-text class="text-center">
							<v-progress-circular color="primary" indeterminate size="60"></v-progress-circular>
						</v-card-text>
					</v-card>
				</v-dialog>

				<v-dialog v-model="resetSuccess" hide-overlay persistent width="400">
					<v-card>
						<div class="text-center">
							<v-card-title class="headline">Password Reset Successful!</v-card-title>
						</div>
						<v-card-text class="text-center">
							<v-icon color="green" size="60">mdi-check-circle</v-icon>
							<p>Redirecting to <a :href="loginUrl">login page</a>...</p>
						</v-card-text>
					</v-card>
				</v-dialog>
			</v-card>
		</div>
	</v-container>
</template>

<script>
import backendService from '@/services/backendService';

export default {
	name: 'ResetPasswordView',
	data() {
		return {
			email: '',
			firstName: '',
			password: '',
			confirmPassword: '',
			showPassword: false,
			loading: false,
			valid: false,
			error: false,
			errorMessage: '',
			passwordStrength: {
				value: 0,
				color: 'red',
				icon: 'mdi-emoticon-sad',
				label: 'Deck Swabber'
			},
			initialLoading: true,
			resetSuccess: false,
			loginUrl: '/login',
			welcome: false, // New data property to track welcome status
			emailRules: [
				v => !!v || 'E-mail is required',
				v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
			],
			passwordRules: [
				v => !!v || 'Password is required',
				v => v.length >= 6 || 'Password must be at least 6 characters long',
			],
			confirmPasswordRules: [
				v => !!v || 'Confirm Password is required',
				v => v === this.password || 'Passwords must match',
			],
		};
	},
	computed: {
		formValid() {
			return this.password && this.confirmPassword && this.password === this.confirmPassword;
		}
	},
	methods: {
		checkPasswordStrength() {
			const strength = this.calculatePasswordStrength(this.password);
			this.passwordStrength.value = strength.value;
			this.passwordStrength.color = strength.color;
			this.passwordStrength.icon = strength.icon;
			this.passwordStrength.label = strength.label;
		},
		calculatePasswordStrength(password) {
			let value = 0;
			let color = 'red';
			let icon = 'mdi-emoticon-sad';
			let label = 'Deck Swabber';

			if (password.length > 6) value += 25;
			if (/[A-Z]/.test(password)) value += 25;
			if (/[0-9]/.test(password)) value += 25;
			if (/[^A-Za-z0-9]/.test(password)) value += 25;

			if (value <= 25) {
				color = 'red';
				icon = 'mdi-emoticon-sad';
				label = 'Deck Swabber';
			} else if (value <= 50) {
				color = 'orange';
				icon = 'mdi-emoticon-neutral';
				label = 'Cabin Boy';
			} else if (value <= 75) {
				color = 'yellow';
				icon = 'mdi-emoticon-happy';
				label = 'Buccaneer';
			} else {
				color = 'green';
				icon = 'mdi-emoticon-excited';
				label = 'Captain';
			}

			return { value, color, icon, label };
		},
		validateConfirmPassword() {
			this.$refs.form.validate();
		},
		async resetPassword() {
			this.$refs.form.validate();
			if (this.valid) {
				this.loading = true;
				this.error = false;

				try {
					// Mock backend call
					const response = await backendService.post('/resetPassword', {
						email: this.email,
						firstName: this.firstName,
						token: this.$route.query.token,
						password: this.password,
					});
					if (!response) {
						throw new Error(response?.message || 'Reset password failed. Please try again.');
					}
					this.resetSuccess = true;
					setTimeout(() => {
						this.$router.push('/login');
					}, 2000);
				} catch (error) {
					this.loading = false;
					this.error = true;
					this.errorMessage = error.message || 'Reset password failed. Please try again.';
				}
			}
		},
		async fetchResetPasswordInfo() {
			this.initialLoading = true;
			this.error = false;
			try {
				const response = await backendService.get('/resetPasswordInfo?token=' + this.$route.query.token);
				this.email = response.email;
				this.firstName = response.firstName;
				this.welcome = response.welcome || false; // Capture welcome status from the response
			} catch (error) {
				this.error = true;
				this.errorMessage = 'Failed to load reset password information. Please try again.';
			} finally {
				this.initialLoading = false;
			}
		}
	},
	mounted() {
		this.fetchResetPasswordInfo();
	},
};
</script>

<style scoped>
.main-container {
	height: 100vh;
	width: 100%;
	flex-direction: column;
}

.reset-password-container {
	display: flex;
	justify-content: center;
	width: 100%;
}

.retry-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.pa-5 {
	padding: 20px;
}

h1 {
	text-wrap: wrap;
}
</style>

import loginService from "./loginService";

async function tryFunction(callback) {
	try {
		return await callback();
	} catch (error) {
		// Check if the message starts with 401 Unauthorized
		if (error.message.startsWith("401 Unauthorized")) {
			// Only goto to login page if not already on the login page, use vue router push
			if (window.location.pathname !== "/login") {
				window.location.href = "/login";
			}
		}
		throw new Error(error.message);
	}
}

function getBaseUrl(url) {
	const baseUrl = process.env.NODE_ENV === 'production'
		? `${window.location.protocol}//${window.location.hostname}/api${url}`
		: `${window.location.protocol}//${window.location.hostname}:3000${url}`;
	return baseUrl;
}

async function fetchData(url, options = {}) {
	const sessionToken = loginService.getSessionToken();
	const baseUrl = getBaseUrl(url);

	// Add sessionToken to the Authorization header
	const headers = {
		'Content-Type': 'application/json',
		...options.headers,
		'Authorization': `Bearer ${sessionToken}`
	};

	const response = await fetch(baseUrl, { ...options, headers });

	if (response.ok) {
		return await response.json();
	} else {
		const errorText = await response.text();
		throw new Error(response.status + " " + response.statusText + (errorText ? ": " + errorText : ""));
	}
}

export default {
	async get(url) {
		return tryFunction(async () => {
			return await fetchData(url);
		});
	},

	async post(url, data) {
		return tryFunction(async () => {
			const stringData = JSON.stringify(data);
			return await fetchData(url, {
				method: 'POST',
				body: stringData,
			});
		});
	}
};

<template>
	<v-dialog v-model="dialog" max-width="500px">
		<v-card>
			<v-card-title>
				<span class="headline">Add Scraper</span>
			</v-card-title>
			<v-card-text>
				<v-text-field v-model="name" :error-messages="nameError" label="Enter Name" required></v-text-field>
				<v-text-field v-model="url" :error-messages="urlError" label="Enter URL" required></v-text-field>
				<v-text-field v-model="scraper" label="Enter Scraper"></v-text-field>
				<v-text-field v-model="functionName" label="Enter Function"></v-text-field>
				<v-select v-model="method" :items="methods" :error-messages="methodError" label="Select Method"
					required></v-select>
				<v-alert v-if="genericError" type="error">{{ genericError }}</v-alert>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
				<v-btn color="blue darken-1" text @click="addScraperFunction">Add</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import backendService from "../../services/backendService";

export default {
	name: "AddScraperDialog",
	data() {
		return {
			dialog: false,
			url: "",
			name: "",
			scraper: "",
			functionName: "",
			method: "",
			urlError: "",
			nameError: "",
			methodError: "",
			genericError: "",
			methods: ["GET", "POST", "PUT", "DELETE"],
		};
	},
	methods: {
		openDialog() {
			this.dialog = true;
		},
		closeDialog() {
			this.dialog = false;
		},
		async addScraperFunction() {
			this.clearErrors();

			if (!this.name) {
				this.nameError = "Name is required.";
			}
			if (!this.url) {
				this.urlError = "URL is required.";
			}
			if (!this.method) {
				this.methodError = "Method is required.";
			}

			if (this.urlError || this.nameError || this.methodError) {
				return;
			}

			try {
				const response = await backendService.post("/scraper/addScraper", {
					url: this.url,
					name: this.name,
					scraper: this.scraper,
					function: this.functionName,
					method: this.method,
				});
				if (response) {
					this.$emit("refreshScrapers");
					this.closeDialog();
				} else {
					this.genericError = "Failed to add scraper. Please try again.";
				}
			} catch (error) {
				this.genericError = "An error occurred while adding the scraper.";
				console.error(error);
			}
		},
		clearErrors() {
			this.urlError = "";
			this.nameError = "";
			this.methodError = "";
			this.genericError = "";
		},
	},
};
</script>

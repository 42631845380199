<template>
	<v-dialog v-model="isOpen" max-width="600px" @click:outside="close">
		<v-card>
			<v-card-title class="text-h5">Torrent Settings</v-card-title>
			<v-card-text>
				<v-form>
					<v-text-field label="Download Limit (KB/s)" v-model="settings.downloadLimit"
						type="number"></v-text-field>
					<v-text-field label="Upload Limit (KB/s)" v-model="settings.uploadLimit"
						type="number"></v-text-field>
					<v-checkbox label="Download Selected Files" v-model="settings['files-wanted']"></v-checkbox>
					<v-checkbox label="High Priority" v-model="settings['priority-high']"></v-checkbox>
					<v-checkbox label="Low Priority" v-model="settings['priority-low']"></v-checkbox>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click="close">Cancel</v-btn>
				<v-btn color="primary" @click="saveSettings">Save</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
//import backendService from "@/services/backendService";

export default {
	props: {
		torrentId: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			isOpen: true,
			settings: {
				downloadLimit: '',
				uploadLimit: '',
				'files-wanted': false,
				'priority-high': false,
				'priority-low': false,
			},
		};
	},
	methods: {
		async saveSettings() {
			try {
				/*await backendService.post(`/downloader/setSettings`, {
					torrentId: this.torrentId,
					settings: this.settings,
				});*/
				this.close();
			} catch (error) {
				console.error('Error saving torrent settings:', error);
			}
		},
		close() {
			this.isOpen = false;
			this.$emit('close');
		},
	},
};
</script>

<template>
	<div class="details-panel">
		<div class="details-container">
			<div class="top-details">
				<hr class="dark-divider" />
				<br />
				<div class="torrent-details">
					<p class="torrent-detail torrent-detail-seeds"><strong>Seeds:</strong><span>{{ item.seeds }}</span>
					</p>
					<p class="torrent-detail torrent-detail-leeches"><strong>Leeches:</strong><span>{{ item.leeches
							}}</span></p>
					<p class="torrent-detail torrent-detail-date"><strong>Date:</strong><span>{{ item.date }}</span></p>
					<p class="torrent-detail torrent-detail-size"><strong>Size:</strong><span>{{ item.size }}</span></p>
					<p class="torrent-detail torrent-detail-uploader"><strong>Uploader:</strong><span>{{ item.uploader
							}}</span></p>
				</div>
			</div>

			<PirateLoadingSpinner v-if="loading" class="ml-4" />
			<div v-else-if="error" class="error-message">Arrr! {{ error }}</div>

			<div v-else class="details-content">
				<hr class="dark-divider" />
				<br />
				<div class="details-main">
					<img :src="details.imageSrc || placeholderImage" @error="handleImageError" @click="openImage"
						alt="placeholder" class="details-image" />
					<div class="details-info">
						<div>
							<p><strong>Name: </strong>{{ details.name }}</p>
							<p><strong>Rating: </strong>{{ details.rating }}</p>
							<p><strong>Release Year: </strong>{{ details.year }}</p>
							<p><strong>Genres: </strong>{{ details.genres }}</p>
							<p v-if="details.type === 'movie' && details.duration"><strong>Duration: </strong>{{
						details.duration }}</p>
							<p><strong>Summary: </strong>{{ details.summary }}</p>
						</div>

						<a @click="toggleDetails" v-if="details.type === 'series' && details.season && details.episode"
							style="color: #0055AA; cursor: pointer;">
							{{ showDetailsFlag ? 'Hide episode details' : 'Reveal episode details' }}
						</a>

						<!-- Loading spinner and error message for show details -->
						<PirateLoadingSpinner v-if="showLoading" class="ml-4" />
						<div v-else-if="showError" class="error-message">Arrr! {{ showError }}</div>

						<!-- Display Show or Episode Details -->
						<div v-if="showDetailsFlag && showDetails">
							<p><strong>Episode Title: </strong>{{ showDetails.episodeTitle }}<br></p>
							<p><strong>Episode Air Date: </strong>{{ showDetails.episodeAirDate }}<br></p>
							<p><strong>Episode Duration: </strong>{{ showDetails.episodeDuration }}</p>
							<p><strong>Episode Rating: </strong>{{ showDetails.episodeRating }}</p>
						</div>

						<!-- Buttons for toggling views -->
						<p class="mt-2">
							<a :href="details.imdbLink" target="_blank">
								<img :src="imdbLogo" alt="IMDB" class="icon" />
							</a>
							<a :href="details.tmdbLink" target="_blank" class="ml-2">
								<img :src="tmdbLogo" alt="TMDB" class="icon" />
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal for image preview -->
		<div v-if="showModal" class="modal" @click="closeImage">
			<img :src="modalImageSrc" @error="handleModalImageError" class="modal-image" />
		</div>
	</div>
</template>

<script>
import imdbLogo from "@/assets/imdb.png";
import tmdbLogo from "@/assets/tmdb.png";

import backendService from '../services/backendService.js';
import PirateLoadingSpinner from './generic/PirateLoadingSpinner.vue';

export default {
	name: "ResultComponentDetails",
	props: {
		item: {
			type: Object,
			required: true,
		},
		detailsUrl: {
			type: String,
			required: true,
		},
	},
	components: {
		PirateLoadingSpinner,
	},
	data() {
		return {
			details: null,
			showDetails: null,
			showDetailsFlag: false,
			loading: true,
			error: null,
			showLoading: false, // New state for show details loading
			showError: null, // New state for show details error
			showModal: false,
			imdbLogo,
			tmdbLogo,
			modalImageSrc: '',
			placeholderImage: 'data:image/svg+xml;base64,' + btoa(
				`<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100">
                    <rect width="100" height="100" fill="white"/>
                    <line x1="0" y1="0" x2="100" y2="100" stroke="red" stroke-width="5"/>
                    <line x1="0" y1="100" x2="100" y2="0" stroke="red" stroke-width="5"/>
                </svg>`
			),
		};
	},
	async created() {
		try {
			this.details = await backendService.get(this.detailsUrl + "?name=" + this.item.name);
		} catch (error) {
			this.error = `Blimey! ${error.message}`;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		handleImageError(event) {
			event.target.src = this.placeholderImage;
		},
		openImage() {
			this.modalImageSrc = this.details.imageSrc ? this.details.imageSrc.replace('_SX300.jpg', '_.jpg') : this.placeholderImage;
			this.showModal = true;
		},
		handleModalImageError(event) {
			if (this.details.imageSrc && event.target.src !== this.details.imageSrc) {
				event.target.src = this.details.imageSrc;
			} else {
				event.target.src = this.placeholderImage;
			}
		},
		closeImage() {
			this.showModal = false;
		},
		async toggleDetails() {
			if (!this.showDetails) {
				this.showLoading = true; // Start show details loading
				try {
					this.showDetails = await backendService.get(this.detailsUrl + "?name=" + this.item.name + "&show=true");
				} catch (error) {
					this.showError = `Blimey! ${error.message}`;
				} finally {
					this.showLoading = false; // End show details loading
				}
			}
			this.showDetailsFlag = !this.showDetailsFlag;
		}
	}
};
</script>


<style scoped>
.details-panel {
	display: flex;
	align-items: center;
	padding: 16px 0px 16px 0px;
	flex-direction: column;
	/* Adjusted for better layout */
}

.details-container {
	width: 100%;
	/* Make container take full width */
}

.top-details {
	display: flex;
	flex-direction: column;
	/* Stack details vertically */
	margin-bottom: 16px;
}

.torrent-details {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.torrent-detail {
	padding: 0 10px;
	/* Added padding between torrent details */
}

.torrent-detail-seeds {
	display: flex;
}

.torrent-detail-leeches {
	display: flex;
}

.torrent-detail-date {
	display: flex;
}

.torrent-detail-size {
	display: none;
}

.torrent-detail-uploader {
	display: flex;
}

.torrent-detail strong {
	margin-right: 5px;
	/* Add padding between the key and value */
}

.dark-divider {
	border: 1px solid rgb(63, 63, 63);
}

.details-main {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.details-image {
	height: 200px;
	width: auto;
	margin-bottom: 16px;
	/* Adjust margin for better layout */
	cursor: pointer;
	object-fit: contain;
	padding: 0px 0px 0px 10px;
}

.details-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.error-message {
	color: red;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.modal-image {
	width: 90%;
	height: 90%;
	object-fit: contain;
	cursor: pointer;
}

.loading-container {
	display: flex;
	align-items: center;
}

.icon {
	width: 32px;
	height: 32px;
	object-fit: contain;
	cursor: pointer;
}


@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@media (min-width: 500px) {
	.details-main {
		flex-direction: row;
		align-items: flex-start;
	}

	.details-image {
		height: auto;
		width: 200px;
		margin-right: 16px;
		margin-bottom: 0;
		/* Adjust margin for better layout */
	}

	.details-info {
		align-items: flex-start;
		text-align: left;
	}

	.top-details {
		display: flex;
	}
}

@media (min-width: 1300px) {
	.top-details {
		display: none;
	}
}

@media (min-width: 900px) {
	.torrent-detail-date {
		display: none;
	}
}

@media (min-width: 600px) {
	.torrent-detail-seeds {
		display: none;
	}

	.torrent-detail-leeches {
		display: none;
	}
}

@media (max-width: 600px) {
	.details-container {
		flex-direction: column;
		align-items: flex-start;
	}

	.details-info {
		align-items: flex-start;
		text-align: left;
	}

	.details-main {
		flex-direction: row;
		align-items: flex-start;
	}

	.top-details {
		display: flex;
	}

	.details-image {
		max-width: 160px;
		height: 160px;
		margin-right: 16px;
		margin-bottom: 0;
	}

	.details-info {
		align-items: flex-start;
		text-align: left;
	}
}
</style>
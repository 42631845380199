<template>
	<div>
		<TVShowResultComponent initialFetchUrl="/topTVShows" searchUrl="/searchTVShows" detailsUrl="/detailsTVShows"
			magnetUrl="/magnetTVShows" :limit="null" />
	</div>
</template>

<script>
import TVShowResultComponent from "@/components/TVShowResultComponent.vue";

export default {
	name: "TVShowsView",
	components: {
		TVShowResultComponent,
	},
};
</script>

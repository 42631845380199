<template>
	<v-container>
		<!-- TV Shows Section -->
		<v-row>
			<v-col cols="12">
				<!-- Unified Container for Header and ResultComponent -->
				<div class="section-wrapper">
					<div class="section-header d-flex align-center justify-space-between clickable-header"
						@click="navigateTo('/shows')" role="button" tabindex="0"
						@keydown.enter.space="navigateTo('/shows')">
						<!-- Added Icon before the title -->
						<div class="d-flex align-center">
							<v-icon color="white">mdi-television-classic</v-icon>
							<h2 class="section-title ml-4">Top 5 Newly Added TV Shows</h2>
						</div>
						<v-btn icon @click.stop="navigateTo('/shows')">
							<v-icon color="blue">mdi-arrow-right</v-icon>
						</v-btn>
					</div>
					<TVShowResultComponent initialFetchUrl="/topTVShows" searchUrl="/searchTVShows"
						detailsUrl="/detailsTVShows" magnetUrl="/magnetTVShows" :showSearch="false" :limit="5"
						:showTopNew="showTopNewShows" />
				</div>
			</v-col>
		</v-row>

		<!-- Movies Section -->
		<v-row class="mb-8">
			<v-col cols="12">
				<!-- Unified Container for Header and ResultComponent -->
				<div class="section-wrapper">
					<div class="section-header d-flex align-center justify-space-between clickable-header"
						@click="navigateTo('/movies')" role="button" tabindex="0"
						@keydown.enter.space="navigateTo('/movies')">
						<!-- Added Icon before the title -->
						<div class="d-flex align-center">
							<v-icon color="white">mdi-filmstrip</v-icon>
							<h2 class="section-title ml-4">Top 5 Newly Added Movies</h2>
						</div>
						<v-btn icon @click.stop="navigateTo('/movies')">
							<v-icon color="blue">mdi-arrow-right</v-icon>
						</v-btn>
					</div>
					<TVShowResultComponent initialFetchUrl="/topMovies" searchUrl="/searchMovies"
						detailsUrl="/detailsMovies" magnetUrl="/magnetMovies" :showSearch="false" :limit="5"
						:showTopNew="showTopNewMovies" />
				</div>
			</v-col>
		</v-row>

		<!-- Games Section -->
		<v-row class="mb-8">
			<v-col cols="12">
				<!-- Unified Container for Header and ResultComponent -->
				<div class="section-wrapper">
					<div class="section-header d-flex align-center justify-space-between clickable-header"
						@click="navigateTo('/games')" role="button" tabindex="0"
						@keydown.enter.space="navigateTo('/games')">
						<!-- Added Icon before the title -->
						<div class="d-flex align-center">
							<v-icon color="white">mdi-gamepad-variant</v-icon>
							<h2 class="section-title ml-4">Top 5 Newly Added Games</h2>
						</div>
						<v-btn icon @click.stop="navigateTo('/games')">
							<v-icon color="blue">mdi-arrow-right</v-icon>
						</v-btn>
					</div>
					<TVShowResultComponent initialFetchUrl="/topGames" searchUrl="/searchGames"
						detailsUrl="/detailsGames" magnetUrl="/magnetGames" :showSearch="false" :limit="5"
						:showTopNew="showTopNewGames" />
				</div>
			</v-col>
		</v-row>

		<!-- Software Section -->
		<v-row class="mb-8">
			<v-col cols="12">
				<!-- Unified Container for Header and ResultComponent -->
				<div class="section-wrapper">
					<div class="section-header d-flex align-center justify-space-between clickable-header"
						@click="navigateTo('/software')" role="button" tabindex="0"
						@keydown.enter.space="navigateTo('/software')">
						<!-- Added Icon before the title -->
						<div class="d-flex align-center">
							<v-icon color="white">mdi-application-cog</v-icon>
							<h2 class="section-title ml-4">Top 5 Newly Added Software</h2>
						</div>
						<v-btn icon @click.stop="navigateTo('/software')">
							<v-icon color="blue">mdi-arrow-right</v-icon>
						</v-btn>
					</div>
					<TVShowResultComponent initialFetchUrl="/topSoftware" searchUrl="/searchSoftware"
						detailsUrl="/detailsSoftware" magnetUrl="/magnetSoftware" :showSearch="false" :limit="5"
						:showTopNew="showTopNewSoftware" />
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import TVShowResultComponent from "@/components/TVShowResultComponent.vue";

export default {
	name: "HomeView",
	components: {
		TVShowResultComponent,
	},
	data() {
		return {
			// Flags to control the display of new torrents
			showTopNewMovies: true,
			showTopNewShows: true,
			showTopNewGames: true,
			showTopNewSoftware: true,
		};
	},
	methods: {
		navigateTo(path) {
			this.$router.push(path);
		},
	},
};
</script>

<style scoped>
/* Unified Container for Header and ResultComponent */
.section-wrapper {
	max-width: 1500px;
	/* Match the max-width of ResultComponent */
	margin: 0 auto;
	/* Center the container */
}

/* Section Header Styles */
.section-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0px;
	padding: 0px 16px;
	border-radius: 8px;
	transition: background-color 0.3s;
	cursor: pointer;
}

.section-header:hover {
	background-color: rgba(0, 0, 0, 0.05);
	/* Light hover effect */
}

.section-title {
	font-weight: bold;
}

.clickable-header {
	cursor: pointer;
}

.v-btn {
	/* Ensure the button doesn't cover the entire header */
}

/* Error Styles (if applicable) */
.error-container {
	display: flex;
	align-items: center;
	color: red;
	margin-top: 10px;
}

.error-text {
	color: red;
	margin-left: 8px;
	font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 1300px) {
	.uploader-column {
		display: none;
	}

	.default-padding {
		padding: 0px 16px 0px 0px !important;
	}
}

@media (max-width: 900px) {
	.date-column {
		display: none;
	}

	.default-padding {
		padding: 0px 8px 0px 0px !important;
	}
}

@media (max-width: 600px) {
	.icon-header {
		display: none;
	}

	.leecher-column {
		display: none;
	}

	.seeder-column {
		display: none;
	}
}
</style>

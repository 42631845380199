<template>
	<BaseResultComponent :initialFetchUrl="initialFetchUrl" :searchUrl="searchUrl" :detailsUrl="detailsUrl"
		:magnetUrl="magnetUrl" :showSearch="showSearch" :limit="limit" :showTopNew="showTopNew">
		<template #table-headers>
			<th class="default-padding icon-header"></th>
			<th class="table-header default-padding">Name</th>
			<th class="table-header default-padding seeder-column">SE</th>
			<th class="table-header default-padding leecher-column">LE</th>
			<th class="table-header default-padding date-column">Date</th>
			<th class="table-header default-padding size-column">Size</th>
			<th class="table-header default-padding uploader-column">Uploader</th>
			<th class="table-header default-padding">Action</th>
		</template>

		<template #table-row="{ item, index }">
			<ResultComponentRow :item="item" :searchUrl="searchUrl" :detailsUrl="detailsUrl" :magnetUrl="magnetUrl"
				:isOdd="index % 2 !== 0" />
		</template>
	</BaseResultComponent>
</template>

<script>
import BaseResultComponent from "./BaseResultComponent.vue";
import ResultComponentRow from "./ResultComponentRow.vue";

export default {
	name: "TVShowResultComponent",
	components: {
		BaseResultComponent,
		ResultComponentRow,
	},
	props: {
		initialFetchUrl: {
			type: String,
			required: true,
		},
		searchUrl: {
			type: String,
			required: true,
		},
		detailsUrl: {
			type: String,
			required: true,
		},
		magnetUrl: {
			type: String,
			required: true,
		},
		showSearch: {
			type: Boolean,
			default: true,
		},
		limit: {
			type: Number,
			default: 10, // Limited on home view
		},
		showTopNew: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style scoped>
.icon {
	padding: 0px 8px 0px 8px !important;
}

.default-padding {
	padding: 0px 48px 0px 0px !important;
}

.no-padding {
	padding: 0px !important;
}

thead {
	background-color: #2e2e2e;
}

.table-header {
	font-weight: bold;
	background-color: #2e2e2e;
}

.v-container {
	padding: 0px !important;
}

.content-container {
	width: 100%;
	max-width: 1500px;
	margin: 0 auto;
}

.table-container {
	width: 100%;
}

.error-container {
	display: flex;
	align-items: center;
	color: red;
	margin-top: 10px;
}

.error-text {
	color: red;
	margin-left: 8px;
	font-weight: bold;
}

@media (max-width: 1300px) {
	.uploader-column {
		display: none;
	}

	.default-padding {
		padding: 0px 16px 0px 0px !important;
	}
}

@media (max-width: 900px) {
	.date-column {
		display: none;
	}

	.default-padding {
		padding: 0px 8px 0px 0px !important;
	}
}

@media (max-width: 600px) {
	.icon-header {
		display: none;
	}

	.leecher-column {
		display: none;
	}

	.seeder-column {
		display: none;
	}
}
</style>

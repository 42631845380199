<template>
	<div>
		<TVShowResultComponent initialFetchUrl="/topSoftware" searchUrl="/searchSoftware" detailsUrl="/detailsSoftware"
			magnetUrl="/magnetSoftware" :limit="null" />
	</div>
</template>

<script>
import TVShowResultComponent from "@/components/TVShowResultComponent.vue";

export default {
	name: "SoftwareView",
	components: {
		TVShowResultComponent,
	},
};
</script>

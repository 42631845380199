class LoginService {
	constructor() {
		this.sessionToken = null;
		this.loadSessionToken();
	}

	loadSessionToken() {
		const token = localStorage.getItem('sessionToken');
		if (token) {
			this.sessionToken = token;
		}
	}

	getSessionToken() {
		return this.sessionToken;
	}

	setSessionToken(token) {
		this.sessionToken = token;
		localStorage.setItem('sessionToken', token);
	}

	isSessionTokenValid() {
		return this.sessionToken !== null;
	}
}

export default new LoginService();

<template>
	<div>
		<v-data-table :headers="headers" :items="scrapers" class="mt-2 elevation-1" :hide-default-header="true"
			:hide-default-footer="true" disable-pagination :items-per-page="-1">
			<template v-slot:[`item.status`]="{ item }">
				<v-icon v-if="item.status === 'Success'" color="green">mdi-check-circle</v-icon>
				<v-icon v-else-if="item.status === 'Error'" color="red">mdi-alert-circle</v-icon>
				<v-icon v-else-if="item.status === 'progress'" class="mdi-spin">mdi-loading</v-icon>
				<v-icon v-else>mdi-help-circle</v-icon>
			</template>
			<template v-slot:[`item.actions`]="{ item, index }">
				<div class="d-flex justify-end">
					<v-btn icon @click="updateScraper(item)">
						<v-icon color="blue">mdi-refresh</v-icon>
					</v-btn>
					<v-btn icon @click="openEditDialog(item)">
						<v-icon color="orange">mdi-pencil</v-icon>
					</v-btn>
					<v-btn icon @click="removeScraper(index)">
						<v-icon color="red">mdi-delete</v-icon>
					</v-btn>
					<v-btn icon @click="openBodyDialog(item)">
						<v-icon color="white">mdi-code-block-tags</v-icon>
					</v-btn>
					<v-btn icon @click="openResponseDialog(item)">
						<v-icon color="purple">mdi-file-document</v-icon>
					</v-btn>
				</div>
			</template>
		</v-data-table>
		<v-checkbox v-model="useCache" label="Use Cache" class="mt-4 ml-2"></v-checkbox>
		<v-btn @click="openAddDialog" color="primary" class="ml-2">Add</v-btn>
		<v-btn color="primary" class="ml-2" @click="toggleTestAllScrapers">
			{{ isTestingAll ? 'Stop' : 'Test All' }}
		</v-btn>

		<add-scraper-dialog ref="addScraperDialog" @refreshScrapers="fetchScrapers" />
		<edit-scraper-dialog ref="editScraperDialog" @refreshScrapers="fetchScrapers" />

		<v-dialog v-model="responseDialog" max-width="80vw">
			<v-card>
				<v-card-title>
					<span class="headline">Scraper Response</span>
				</v-card-title>
				<v-card-text>
					<v-textarea v-model="currentResponse" label="Response" readonly auto-grow></v-textarea>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeResponseDialog">Close</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="snackbar" :timeout="snackbarTimeout" :color="snackbarColor">
			{{ snackbarMessage }}
			<v-btn color="white" text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</div>
</template>

<script>
import backendService from "../../services/backendService";
import AddScraperDialog from "./AddScraperDialog.vue";
import EditScraperDialog from "./EditScraperDialog.vue";

export default {
	name: "ScraperTableComponent",
	components: {
		AddScraperDialog,
		EditScraperDialog,
	},
	data() {
		return {
			responseDialog: false,
			currentResponse: "",
			scrapers: [],
			snackbar: false,
			snackbarMessage: "",
			snackbarColor: "",
			snackbarTimeout: 3000,
			headers: [
				{ text: 'Status', value: 'status', sortable: false },
				{ text: 'Name', value: 'name' },
				{ text: 'URL', value: 'url' },
				{ text: 'Actions', value: 'actions', sortable: false },
			],
			isTestingAll: false,
			stopTesting: false,
			useCache: true,
		};
	},
	created() {
		this.fetchScrapers();
	},
	methods: {
		async fetchScrapers() {
			try {
				const response = await backendService.get("/scraper/listScrapers");
				if (response) {
					this.scrapers = response;
				} else {
					this.showSnackbar("Failed to fetch scrapers.", "error");
				}
			} catch (error) {
				this.showSnackbar("An error occurred while fetching scrapers.", "error");
				console.error(error);
			}
		},
		async updateScraper(scraper) {
			try {
				scraper.status = "progress";
				const newScraper = await backendService.get(`/scraper/scrape?name=${scraper.name}&useCache=${this.useCache}`);
				const index = this.scrapers.findIndex((s) => s.name === scraper.name);
				this.scrapers[index] = newScraper;
			} catch (error) {
				this.showSnackbar("An error occurred while executing the scraper.", "error");
				console.error(error);
			}
		},
		async toggleTestAllScrapers() {
			if (this.isTestingAll) {
				this.isTestingAll = false;
				this.stopTesting = true;
				for (const scraper of this.scrapers) {
					scraper.status = "";
				}
			} else {
				this.isTestingAll = true;
				this.stopTesting = false;
				await this.testAllScrapers();
				this.isTestingAll = false;
			}
		},
		async testAllScrapers() {
			for (const scraper of this.scrapers) {
				scraper.status = "queued";
			}

			var first = true;
			for (const scraper of this.scrapers) {
				if (this.stopTesting) {
					this.showSnackbar("Testing stopped", "info");
					break;
				}
				if (!first) {
					scraper.status = "progress";
					await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
				}
				if (this.stopTesting) {
					this.showSnackbar("Testing stopped", "info");
					break;
				}
				await this.updateScraper(scraper);
				first = false;
			}
			if (!this.stopTesting) {
				this.showSnackbar("All scrapers tested", "success");
			}
		},
		openAddDialog() {
			this.$refs.addScraperDialog.openDialog();
		},
		openEditDialog(scraper) {
			this.$refs.editScraperDialog.openDialog(scraper);
		},
		async removeScraper(index) {
			try {
				const scraper = this.scrapers[index];
				const response = await backendService.get(`/scraper/deleteScraper?name=${scraper.name}`);
				if (response) {
					this.showSnackbar("Scraper removed successfully!", "success");
					this.scrapers.splice(index, 1);
				} else {
					this.showSnackbar("Failed to remove scraper. Please try again.", "error");
				}
			} catch (error) {
				this.showSnackbar("An error occurred while removing the scraper.", "error");
				console.error(error);
			}
		},
		openBodyDialog(scraper) {
			this.currentResponse = scraper.body || "No body available.";
			this.responseDialog = true;
		},
		openResponseDialog(scraper) {
			this.currentResponse = scraper.response || "No response available.";
			this.responseDialog = true;
		},
		closeResponseDialog() {
			this.responseDialog = false;
		},
		showSnackbar(message, color) {
			this.snackbarMessage = message;
			this.snackbarColor = color;
			this.snackbar = true;
		},
	},
};
</script>

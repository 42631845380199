import { ref } from 'vue';
import backendService from './backendService';

class UserProfileService {
	constructor() {
		this.userProfile = null;
		this.isLoading = ref(true);
		this.userProfilePromise = this.loadUserProfile();
	}

	async loadUserProfile() {
		try {
			this.userProfile = await backendService.get('/profile/getUserProfile');
			this.isLoading.value = false;
		}
		catch (error) {
			console.error(error);
		}
	}

	async waitUntilLoaded() {
		await this.userProfilePromise;
	}

	getUserProfile() {
		return this.userProfile;
	}

	async asyncGetUserProfile() {
		await this.waitUntilLoaded();
		return this.getUserProfile();
	}
}

export default new UserProfileService();

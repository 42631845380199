<template>
	<div>
		<v-text-field v-model="url" label="Enter URL" clearable append-outer-icon="mdi-web"></v-text-field>
		<v-btn @click="fetchSite" :loading="loading" color="primary" class="mb-4">Download Site</v-btn>
		<v-alert v-if="error" type="error" icon="mdi-alert-circle" dismissible>{{ error }}</v-alert>
		<v-textarea v-model="htmlContent" readonly ref="textarea" auto-grow label="HTML Content"
			hide-details></v-textarea>
		<v-btn v-if="htmlContent" @click="copyToClipboard" class="fixed-bottom right-4" color="primary">Copy
			HTML</v-btn>
	</div>
</template>

<script>
import backendService from "../../services/backendService";

export default {
	name: "WebDownloaderComponent",
	data() {
		return {
			url: "",
			htmlContent: "",
			loading: false,
			error: "",
		};
	},
	methods: {
		async fetchSite() {
			if (!this.url) {
				this.error = "Please enter a valid URL.";
				return;
			}

			this.loading = true;
			this.error = "";
			try {
				const encodedUrl = encodeURIComponent(this.url);
				const response = await backendService.get(`/proxy?url=${encodedUrl}`);
				if (response.ok) {
					const text = await response.text();
					this.htmlContent = text;
					this.adjustTextAreaHeight();
				} else {
					this.htmlContent = "";
					this.error = "Failed to fetch the URL. Please check the URL and try again.";
				}
			} catch (error) {
				this.htmlContent = "";
				this.error = "An error occurred while fetching the URL.";
				console.error(error);
			} finally {
				this.loading = false;
			}
		},
		copyToClipboard() {
			navigator.clipboard.writeText(this.htmlContent).then(
				() => {
					this.showSnackbar("HTML content copied to clipboard.", "success");
				},
				(err) => {
					this.error = "Failed to copy content.";
					console.error(err);
				}
			);
		},
		adjustTextAreaHeight() {
			this.$nextTick(() => {
				const textarea = this.$refs.textarea;
				textarea.style.height = "auto";
				textarea.style.height = textarea.scrollHeight + "px";
			});
		},
		showSnackbar(message, color) {
			this.$emit("show-snackbar", message, color);
		},
	},
	watch: {
		htmlContent() {
			this.adjustTextAreaHeight();
		},
	},
};
</script>

<style scoped>
.fixed-bottom {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 1000;
}

.right-4 {
	right: 16px;
}

.v-textarea__content {
	overflow: hidden !important;
}
</style>

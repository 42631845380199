<template>
	<v-dialog v-model="isOpen" max-width="600px" @click:outside="close">
		<v-card>
			<v-card-title class="text-h5">Delete Torrent</v-card-title>
			<v-card-text>
				<p>Are you sure you want to delete this torrent?</p>
				<p class="mt-2">
					<span style="font-size: 12px;">{{ torrentName }}</span>
				</p>
				<v-checkbox label="Delete Files" v-model="deleteData"></v-checkbox>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click="close">Cancel</v-btn>
				<v-btn color="red" @click="confirmDelete">Delete</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>


<script>
export default {
	props: {
		torrentId: {
			type: Number,
			required: true,
		},
		torrentName: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			isOpen: true,
			deleteData: false,
		};
	},
	methods: {
		confirmDelete() {
			this.$emit('confirmDelete', this.deleteData);
			this.close();
		},
		close() {
			this.isOpen = false;
			this.$emit('close');
		},
	},
};
</script>

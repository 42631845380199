<template>
	<v-container class="d-flex align-center justify-center main-container">
		<div class="logo-container">
			<v-img :src="logo" alt="Logo" max-width="512px"></v-img>
		</div>
		<div class="login-container" :class="{ shake: error }">
			<v-card class="pa-5" max-width="400">
				<v-card-title class="justify-center">
					<h1>Pirate Plunder</h1>
				</v-card-title>
				<v-card-text>
					<v-form ref="form" v-model="valid">
						<v-text-field v-model="email" label="Email" :rules="emailRules" required autocomplete="email"
							@keyup.enter="focusPassword" :readonly="loading || confettiPlaying"></v-text-field>
						<v-text-field v-model="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
							:rules="passwordRules" :type="showPassword ? 'text' : 'password'" label="Password"
							@click:append="showPassword = !showPassword" @keyup.enter="login" ref="passwordField"
							required autocomplete="current-password"
							:readonly="loading || confettiPlaying"></v-text-field>
						<v-btn color="primary" :loading="loading && !loginSuccess" @click="login"
							:disabled="loading || confettiPlaying">
							<span v-if="!loginSuccess">Set Sail</span>
							<v-icon v-else>mdi-check</v-icon>
						</v-btn>
						<v-btn text @click="showForgotPasswordDialog">Lost yer password?</v-btn>
						<v-alert v-if="error" type="error" outlined class="mt-3">
							<v-icon left>mdi-alert-circle</v-icon>
							{{ errorMessage }}
						</v-alert>
					</v-form>
				</v-card-text>
			</v-card>
		</div>
		<ForgottenPasswordPopup v-model="forgotPasswordDialog" :email="email" @close="closeForgotPasswordDialog" />
	</v-container>
</template>

<script>
import logo from "@/assets/LogoTransparent.png";
import backendService from "@/services/backendService";
import loginService from "@/services/loginService";
import confetti from "canvas-confetti";
import ForgottenPasswordPopup from "@/components/login/ForgottenPasswordPopup.vue";

export default {
	name: 'LoginView',
	components: {
		ForgottenPasswordPopup
	},
	data() {
		return {
			logo,
			email: '',
			password: '',
			showPassword: false,
			loading: false,
			valid: false,
			error: false,
			errorMessage: '',
			confettiPlaying: false,
			loginSuccess: false,
			width: 512,
			height: 512,
			emailRules: [
				v => !!v || 'E-mail be required',
				v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
			],
			passwordRules: [
				v => !!v || 'Password be required',
				v => v.length >= 6 || 'Password must be at least 6 characters long',
			],
			forgotPasswordDialog: false,
		};
	},
	methods: {
		async login() {
			this.$refs.form.validate();
			if (this.valid) {
				this.loading = true;
				this.error = false;

				try {
					const loginResponse = await backendService.post('/login', {
						email: this.email,
						password: this.password,
					});
					if (loginResponse) {
						loginService.setSessionToken(loginResponse.sessionToken);
						this.loginSuccess = true;
						this.triggerConfetti();
					}
				} catch (error) {
					this.loading = false;
					this.error = true;
					this.errorMessage = error.message || 'Login failed. Please try again.';
					this.shakeAnimation();
				}
			}
		},
		showForgotPasswordDialog() {
			this.forgotPasswordDialog = true;
		},
		closeForgotPasswordDialog() {
			this.forgotPasswordDialog = false;
		},
		focusPassword() {
			this.$refs.passwordField.focus();
		},
		shakeAnimation() {
			const container = this.$el.querySelector('.login-container');
			container.classList.add('shake');
			setTimeout(() => {
				container.classList.remove('shake');
			}, 820);
		},
		triggerConfetti() {
			this.confettiPlaying = true;
			confetti({
				particleCount: 100,
				spread: 70,
				origin: { y: 0.6 }
			});
			setTimeout(() => {
				this.confettiPlaying = false;
				this.loading = false;
				this.$router.push('/');
			}, 2000); // Wait for 2 seconds before routing
		},
	},
};
</script>

<style scoped>
.main-container {
	height: 100vh;
	width: 100%;
	flex-direction: column;
}

.logo-container {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
}

.logo-outline {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.logo-outline svg {
	position: absolute;
}

.animated-path {
	animation: rotateOutline 2s linear infinite;
	transform-origin: center;
}

@keyframes rotateOutline {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.login-container {
	display: flex;
	justify-content: center;
	width: 100%;
}

.pa-5 {
	padding: 20px;
}

.shake {
	animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000px;
}

@keyframes shake {

	10%,
	90% {
		transform: translate3d(-2px, 0, 0);
	}

	20%,
	80% {
		transform: translate3d(4px, 0, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-8px, 0, 0);
	}

	40%,
	60% {
		transform: translate3d(8px, 0, 0);
	}
}

@media (min-width: 800px) {
	.main-container {
		flex-direction: row;
	}

	.logo-container {
		width: 50%;
		display: flex;
		justify-content: flex-end;
		margin-bottom: 0;
	}

	.login-container {
		width: 50%;
		display: flex;
		justify-content: flex-start;
	}
}
</style>

<template>
	<div>
		<TVShowResultComponent initialFetchUrl="/topMovies" searchUrl="/searchMovies" detailsUrl="/detailsMovies"
			magnetUrl="/magnetMovies" :limit="null" />
	</div>
</template>

<script>
import TVShowResultComponent from "@/components/TVShowResultComponent.vue";

export default {
	name: "MoviesView",
	components: {
		TVShowResultComponent,
	},
};
</script>

<template>
	<div>
		<BookResultComponent initialFetchUrl="" searchUrl="/searchBooks" detailsUrl="/detailsBooks"
			magnetUrl="/magnetBooks" :limit="null" />
	</div>
</template>

<script>
import BookResultComponent from "@/components/BookResultComponent.vue";

export default {
	name: "BooksView",
	components: {
		BookResultComponent,
	},
};
</script>

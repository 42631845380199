// File: src/services/localBackendService.js
import backendService from "./backendService";
import pako from "pako";

// Utility functions for Base64 encoding and decoding
/**
 * Encodes a string to Base64.
 * @param {string} str - The string to encode.
 * @returns {string} - The Base64 encoded string.
 */
function base64Encode(str) {
	try {
		return btoa(unescape(encodeURIComponent(str)));
	} catch (e) {
		console.error("Base64 encoding failed:", e);
		return null;
	}
}

const LOCAL_STORAGE_PREFIX = "localBackendService_";

// Duration for cache validity in milliseconds (5 minutes)
const CACHE_VALIDITY_DURATION = 5 * 60 * 1000;

/**
 * Generates a Base64 encoded key for localStorage.
 * @param {string} url - The original URL.
 * @returns {string} - The Base64 encoded key with prefix.
 */
function getLocalStorageKey(url) {
	const encodedUrl = base64Encode(url);
	return encodedUrl ? `${LOCAL_STORAGE_PREFIX}${encodedUrl}` : `${LOCAL_STORAGE_PREFIX}${url}`;
}

/**
 * Compresses a string using Pako (gzip) and encodes it to Base64.
 * @param {string} str - The string to compress.
 * @returns {string|null} - The Base64 encoded compressed string or null on failure.
 */
function compressAndEncode(str) {
	try {
		const compressed = pako.gzip(str);
		// Convert Uint8Array to Base64
		let binary = "";
		for (let i = 0; i < compressed.length; i++) {
			binary += String.fromCharCode(compressed[i]);
		}
		return btoa(binary);
	} catch (e) {
		console.error("Compression and encoding failed:", e);
		return null;
	}
}

/**
 * Decodes a Base64 string and decompresses it using Pako (inflate).
 * @param {string} base64Str - The Base64 encoded compressed string.
 * @returns {string|null} - The decompressed string or null on failure.
 */
function decodeAndDecompress(base64Str) {
	try {
		const binary = atob(base64Str);
		const len = binary.length;
		const compressed = new Uint8Array(len);
		for (let i = 0; i < len; i++) {
			compressed[i] = binary.charCodeAt(i);
		}
		const decompressed = pako.inflate(compressed, { to: 'string' });
		return decompressed;
	} catch (e) {
		console.error("Decoding and decompression failed:", e);
		return null;
	}
}

/**
 * Retrieves the cache entry from localStorage, including data and timestamp.
 * @param {string} url - The URL used as the key.
 * @returns {Object|null} - The cache entry containing data and timestamp, or null if not found or on error.
 */
function getCacheEntry(url) {
	const key = getLocalStorageKey(url);
	const compressedData = localStorage.getItem(key);
	if (compressedData) {
		const jsonString = decodeAndDecompress(compressedData);
		if (jsonString) {
			try {
				const parsed = JSON.parse(jsonString);
				// Ensure both data and timestamp are present
				if (parsed && typeof parsed === "object" && "data" in parsed && "timestamp" in parsed) {
					return parsed;
				} else {
					console.warn(`Invalid cache format for key ${key}.`);
					return null;
				}
			} catch (e) {
				console.error(`Error parsing JSON for key ${key}:`, e);
				return null;
			}
		} else {
			console.warn(`Decompression returned null for key ${key}.`);
			return null;
		}
	}
	return null;
}

const localBackendService = {
	/**
	 * Synchronously retrieves and decompresses data from localStorage.
	 * @param {string} url - The URL used as the key.
	 * @returns {any|null} - The parsed data or null if not found or on error.
	 */
	localGet(url) {
		const cacheEntry = getCacheEntry(url);
		return cacheEntry ? cacheEntry.data : null;
	},

	/**
	 * Asynchronously fetches data from the backend, compresses it, and stores it in localStorage.
	 * If allowLocalStorage is true and cached data is recent, returns cached data instead of fetching.
	 * @param {string} url - The URL to fetch data from.
	 * @param {boolean} allowLocalStorage - Whether to use localStorage caching.
	 * @returns {Promise<any>} - The fetched or cached data.
	 */
	async remoteGet(url, allowLocalStorage = true) {
		if (allowLocalStorage) {
			const cacheEntry = getCacheEntry(url);
			if (cacheEntry) {
				const currentTime = Date.now();
				if ((currentTime - cacheEntry.timestamp) < CACHE_VALIDITY_DURATION) {
					// Cached data is still valid
					return cacheEntry.data;
				}
			}
		}

		try {
			const data = await backendService.get(url);
			const key = getLocalStorageKey(url);
			try {
				const cacheObject = {
					data,
					timestamp: Date.now()
				};
				const jsonString = JSON.stringify(cacheObject);
				const compressedData = compressAndEncode(jsonString);
				if (compressedData) {
					localStorage.setItem(key, compressedData);
				} else {
					console.warn(`Compression returned null for key ${key}. Data not stored.`);
				}
			} catch (e) {
				console.error(`Error compressing or saving data to localStorage for key ${key}:`, e);
				// Optionally handle storage limits or fallback mechanisms here
			}
			return data;
		} catch (error) {
			console.error(`Error fetching data from backend for URL ${url}:`, error);
			throw error; // Re-throw the error after logging
		}
	},

	/**
	 * Removes data from localStorage for a given URL.
	 * @param {string} url - The URL whose data should be removed.
	 */
	removeGet(url) {
		const key = getLocalStorageKey(url);
		localStorage.removeItem(key);
	}
};

export default localBackendService;
